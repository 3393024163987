import React, { Component } from 'react';
import { View, TextInput, TouchableOpacity, Text, StyleSheet, Linking, Alert } from 'react-native';
import Feather from 'react-native-vector-icons/Feather';

export default class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      confirmPw: '',
      username: '',
      userSource: '',
      platformUsername: '',
      check_textInputChange: false,
      secureTextEntry: true,
      confirmSecureTextEntry: true,
    };
  }

  InsertRecord = () => {
    const {
      email,
      password,
      username,
      confirmPw,
      userSource,
      platformUsername,
    } = this.state;
  
    // Regex to validate email format
    const checkEmail = RegExp(/^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,3})$/i);
  
    // Validation checks for input fields
    if (
      email.length === 0 ||
      password.length === 0 ||
      confirmPw.length === 0 ||
      username.length === 0 ||
      userSource.length === 0 ||
      platformUsername.length === 0
    ) {
      alert('Required Field Is Missing');
    } else if (!checkEmail.test(email)) {
      alert('Invalid email!');
    } else if (password.length < 8) {
      alert('Minimum 8 characters required');
    } else if (!/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password)) {
      alert('Use at least 1 special character');
    } else if (/\s/.test(password)) {
      alert("Don't include space in password");
    } else if (password !== confirmPw) {
      alert('Password does not match');
    } else {
      const InsertAPIURL = 'https://collabbay.com/SignUp.php'; // API to render signup
  
      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      };
  
      const Data = {
        Email: email,
        Password: password,
        Username: username,
        UserSource: userSource,
        PlatformUsername: platformUsername,
      };
  
      // Make the API request
      fetch(InsertAPIURL, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(Data),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log('API Response:', response); // Log the entire response for debugging
  
          if (response && response.Status) {
            if (response.Status === 'Success' || response.Status === 'PartialSuccess') {
              alert(response.Message || 'User registered successfully.');
              // Refresh the page or navigate as needed
              window.location.reload(); 
            } else {
              alert(response.Message || 'Registration failed. Please try again.');
            }
          } else {
            alert('Unexpected response format or no message received');
          }
        })
        .catch((error) => {
          alert('Error Occurred: ' + error); // Handle errors
        });
    }
  };
  
  // Toggle password visibility for the password field
  updateSecureTextEntry = () => {
    this.setState({
      secureTextEntry: !this.state.secureTextEntry,
    });
  };

  // Toggle password visibility for the confirm password field
  updateConfirmSecureTextEntry = () => {
    this.setState({
      confirmSecureTextEntry: !this.state.confirmSecureTextEntry,
    });
  };

  render() {
    return (
      <View style={styles.container}>
        {/* Back Button */}
        <TouchableOpacity style={styles.backButton} onPress={() => this.props.navigation.goBack()}>
          <Feather name="arrow-left" size={24} color="#000" />
        </TouchableOpacity>

        {/* Header */}
        <Text style={styles.header}>Sign Up Now</Text>
        <Text style={styles.subText}>your collabs are waiting</Text>

        {/* Username Input */}
        <TextInput
          placeholder="Create Username"
          placeholderTextColor="#999"
          style={styles.textInput}
          onChangeText={(username) => this.setState({ username })}
        />

        {/* Email Input */}
        <TextInput
          placeholder="Enter Email"
          placeholderTextColor="#999"
          style={styles.textInput}
          onChangeText={(email) => this.setState({ email })}
        />

        {/* Password requirements info */}
        <Text style={styles.subPasswordText}>Minimum 8 characters | 1 Special character | No spaces</Text>

        {/* Password Input */}
        <View style={styles.passwordContainer}>
          <TextInput
            placeholder="Enter Password"
            placeholderTextColor="#999"
            secureTextEntry={this.state.secureTextEntry}
            style={styles.textInput}
            onChangeText={(password) => this.setState({ password })}
          />
          <TouchableOpacity style={styles.icon} onPress={this.updateSecureTextEntry}>
            {this.state.secureTextEntry ? (
              <Feather name="eye-off" color="#999" size={20} />
            ) : (
              <Feather name="eye" color="#333" size={20} />
            )}
          </TouchableOpacity>
        </View>

        {/* Confirm Password Input */}
        <View style={styles.passwordContainer}>
          <TextInput
            placeholder="Confirm Password"
            placeholderTextColor="#999"
            style={styles.textInput}
            onChangeText={(confirmPw) => this.setState({ confirmPw })}
            secureTextEntry={this.state.confirmSecureTextEntry}
          />
          <TouchableOpacity style={styles.icon} onPress={this.updateConfirmSecureTextEntry}>
            {this.state.confirmSecureTextEntry ? (
              <Feather name="eye-off" color="#999" size={20} />
            ) : (
              <Feather name="eye" color="#333" size={20} />
            )}
          </TouchableOpacity>
        </View>

        {/* User Source Input */}
        <TextInput
          placeholder="How did you hear about us?"
          placeholderTextColor="#999"
          style={styles.textInput}
          onChangeText={(userSource) => this.setState({ userSource })}
        />

        {/* Platform Username Input */}
        <TextInput
          placeholder="@Instagram Username"
          placeholderTextColor="#999"
          style={styles.textInput}
          onChangeText={(platformUsername) => this.setState({ platformUsername })}
        />

        {/* Register Button */}
        <TouchableOpacity style={styles.registerButton} onPress={this.InsertRecord}>
          <Text style={styles.buttonText}>Create Account</Text>
        </TouchableOpacity>

        {/* Privacy Policy Link */}
        <TouchableOpacity onPress={() => Linking.openURL('https://collabbay.com/PrivacyPolicy.html')}>
          <Text style={styles.linkText}>By signing up, you agree to our Privacy Policy.</Text>
        </TouchableOpacity>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
  },
  backButton: {
    position: 'absolute',
    top: 40,
    left: 20,
  },
  header: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 8,
    color: 'black',
  },
  subText: {
    fontSize: 14,
    color: '#999',
    marginBottom: 20,
  },
  subPasswordText: {
    fontSize: 12,
    color: '#999',
    padding: 10,
    marginTop: 5,
    marginBottom: 5,
  },
  textInput: {
    width: '80%',
    height: 50,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    marginBottom: 20,
    fontSize: 16,
    paddingHorizontal: 10,
    color: 'black',
  },
  passwordContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  icon: {
    position: 'absolute',
    right: 10,
  },
  registerButton: {
    backgroundColor: '#FDF05D',
    width: '80%',
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    marginBottom: 20,
  },
  buttonText: {
    color: 'black',
    fontSize: 18,
  },
  linkText: {
    fontSize: 9,
    color: 'black',
    textDecorationLine: 'underline',
  },
});
